import React, {useState} from "react";
import "../../../AdvisoryForm.css";
import Grid from "@material-ui/core/Grid";
import {observer} from "mobx-react";
import {UpdateAdvisoryForm} from "./UpdateAdvisoryForm";
import {useHistory, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import createConfig from "../../../config";

export const UpdateAdvisoryView = observer(
    ({formData, cdsid, updateAdvisoryClient, bulkMailInfoAPIClient, initialBulkMail}) => {
        let history = useHistory();
        const config = createConfig(window.location.hostname);

        let {updateId} = useParams();
        const handleSubmit = formType => {
            const data = formData.toJson;
            let emails = data.contactInfo
                .concat(data.additionalEmails);

            if (data.sendBulkmails) {
                emails = emails.concat(bulkMail);
            }

            updateAdvisoryClient
                .submit(data, updateId)
                .then(() => {
                    history.push({
                        pathname: "/successConfirmation",
                        state: {
                            emails: emails,
                            formType: formType
                        }
                    });
                })
                .catch(() => {
                    history.push({
                        pathname: "/failureConfirmation"
                    });
                });
        };

        const handleRegionChange = name => event => {
            formData.regions = {...formData.regions, [name]: event.target.checked};
            updateBulkMail();
        };
        const handlePriorityChange = event => {
            formData.priority = event.target.value;
            updateBulkMail();
        };
        const handleStatusChange = event => {
            formData.status = event.target.value;
            if (event.target.value === "Final" && !formData.endDate) {
                formData.endDate = new Date();
            }
            if (
                event.target.value === "Final" &&
                !formData.businessImpactDetails.biEndDate
            ) {
                formData.businessImpactDetails.biEndDate = new Date();
            }
            setInvalidContactInfoEmail("");
            setInvalidAdditionalEmail("");
        };
        const [showEndDate, setShowEndDate] = useState(!!formData.endDate);
        const [showBiEndDate, setShowBiEndDate] = useState(!!formData.businessImpactDetails.biEndDate);
        const [showRootCause, setShowRootCause] = useState(!!formData.rootCause);
        const [bulkMail, setBulkMail] = useState(initialBulkMail);
        const [isFinalAdvisory] = useState(formData.status.includes("Final"));
        const [invalidContactInfoEmail, setInvalidContactInfoEmail] = useState("");
        const [invalidAdditionalEmail, setInvalidAdditionalEmail] = useState("");


        function formatInvalidEmails() {
            if (invalidContactInfoEmail || invalidAdditionalEmail) {
                let commaSeparate =
                    invalidContactInfoEmail && invalidAdditionalEmail ? ", " : "";
                return invalidContactInfoEmail + commaSeparate + invalidAdditionalEmail;
            }
            return "";
        }

        function handleRemoveRootCause() {
            formData.rootCause = "";
            setShowRootCause(false);
        }

        function handleRemoveEnd() {
            setShowEndDate(false);
            formData.endDate = null;
        }

        function handleRemoveBiEnd() {
            setShowBiEndDate(false);
            formData.businessImpactDetails.biEndDate = null;
        }

        const useStyles = makeStyles(theme => ({
            selectors: {
                margin: theme.spacing(1),
                minWidth: 150
            }
        }));

        const stylingClasses = useStyles();

        const updateBulkMail = () => {
            if(formData.status.includes("Executive")) return
            const regions = formData.getRegionsFromForm(formData.regions);

            bulkMailInfoAPIClient.getBulkMailInfo(
                regions,
                formData.priority
            ).then(response => {
                setBulkMail(response);

            }).catch(error => console.warn(error));
        };

        function getDisabled() {

         if ( formData.updateSituationBackground.length > config.validationconfig.inputFields['updateSituationBackground'].maxLength 
           || formData.rootCause.length > config.validationconfig.inputFields['rootCause'].maxLength 
           || formData.updateBusinessImpact.length > config.validationconfig.inputFields['updateBusinessImpact'].maxLength
           || formData.businessImpactDetails.usersImpacted.length > config.validationconfig.inputFields['usersImpacted'].maxLength ){
            return true
          }

            return formData.updateSituationBackground === ""
        }


        return (

                <Grid container spacing={3}>
                    <UpdateAdvisoryForm
                        isFinalAdvisory={isFinalAdvisory}
                        data={formData}
                        cdsid={cdsid}
                        updateId={updateId}
                        handleStatusChange={handleStatusChange}
                        handleRegionChange={handleRegionChange}
                        showEndDate={showEndDate}
                        setShowEndDate={setShowEndDate}
                        showBiEndDate={showBiEndDate}
                        setShowBiEndDate={setShowBiEndDate}
                        showRootCause={showRootCause}
                        setShowRootCause={setShowRootCause}
                        handleRemoveEnd={handleRemoveEnd}
                        handleRemoveBiEnd={handleRemoveBiEnd}
                        handleRemoveRootCause={handleRemoveRootCause}
                        handleSubmit={handleSubmit}
                        stylingClasses={stylingClasses}
                        bulkMail={bulkMail}
                        handlePriorityChange={handlePriorityChange}
                        setInvalidContactInfoEmail={setInvalidContactInfoEmail}
                        setInvalidAdditionalEmail={setInvalidAdditionalEmail}
                        invalidEmails={formatInvalidEmails()}
                        submitDisabled={getDisabled}
                    />
                </Grid>


        );
    }
);
