import React from "react";
import {useMatomo} from "@datapunt/matomo-tracker-react";


export const slackChannelLabel = "SRE-Community"
export const slackChannelURL = "https://ford-eeit-cc.slack.com/archives/CM9E024P6"

export const webexSpaceLabel = "SRE Community"
export const webexSpaceURL = "https://www.webexteams.ford.com/space?r=4vvn"

export const FAMCommunityLinks = ()=>{
    let {trackEvent} = useMatomo();
    return(
        <>
            <p><strong>Slack: <a href={slackChannelURL} target={"_blank"}
                                 onClick={() => {
                                     trackEvent({
                                         category: 'Community',
                                         action: 'Slack Community link clicked',
                                         documentTitle: 'Ford Advisory Management',
                                         href: 'advisory.sre.ford.com',
                                     })
                                 }}>{slackChannelLabel}</a></strong>
            </p>
            <p><strong>WebEx:</strong> Search for <strong><a href={webexSpaceURL}
                                                             onClick={() => {
                                                                 trackEvent({
                                                                     category: 'Community',
                                                                     action: 'Webex Community link clicked',
                                                                     documentTitle: 'Ford Advisory Management',
                                                                     href: 'advisory.sre.ford.com',
                                                                 })
                                                             }}>{webexSpaceLabel}</a></strong>
            </p>
            <p>Go back to the <strong><a href={"https://advisory.sre.ford.com/"}>Ford Advisory Management
                Homepage</a></strong></p>
        </>
    )
}