import React, {useState} from "react";
import "../../../AdvisoryForm.css";
import Grid from "@material-ui/core/Grid";
import {CreateAdvisoryForm} from "./CreateAdvisoryForm";
import {observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {useMatomo} from '@datapunt/matomo-tracker-react';
import createConfig from "../../../config";

export function replaceAngleBracketsWithWords(userInput) {
    return userInput.toString().replace("<", "less than").replace(">", "greater than")
}

export function getDisabledForCreate(formData, showEndDate, showBiEndDate) {
    const config = createConfig(window.location.hostname);
    let eitherEndDateIsNull = formData.endDate === null || formData.businessImpactDetails.biEndDate === null;
    let notInitialAndEitherEndDateIsNull = formData.status!=="Initial" && eitherEndDateIsNull;
    let isInitialAndVisibleEndDateIsNull = formData.status === "Initial" && (showEndDate && formData.endDate === null || showBiEndDate && formData.businessImpactDetails.biEndDate === null);
    if(isInitialAndVisibleEndDateIsNull || notInitialAndEitherEndDateIsNull){
        return true
    }
    if ( formData.product.length > config.validationconfig.inputFields['product'].maxLength 
         || formData.rootCause.length > config.validationconfig.inputFields['rootCause'].maxLength 
         || formData.situationBackground.content.length > config.validationconfig.inputFields['situationbackground'].maxLength
         || formData.businessImpactDetails.usersImpacted.length > config.validationconfig.inputFields['usersImpacted'].maxLength
         || formData.businessImpactDetails.impactDetails.content.length > config.validationconfig.inputFields['impactDetails'].maxLength ){
            return true
         }

    return formData.product === "" || formData.contactInfo === "" || formData.situationBackground.content === "";
    
}

export const CreateAdvisory = observer(
    ({formData, submitFormClient, bulkMailInfoAPIClient}) => {
        const {trackEvent} = useMatomo();
        let history = useHistory();

        const handleSubmit = () => {
            const data = formData.toJson;
            let emails = data.contactInfo
                .concat(data.additionalEmails);

            if (data.sendBulkmails) {
                emails = emails.concat(bulkMail);
            }

            submitFormClient
                .submit(data)
                .then(() => {
                    history.push({
                        pathname: "/successConfirmation",
                        state: {
                            emails: emails
                        }
                    });
                })
                .then(() => {
                    trackEvent({
                        category: 'SubmitForm',
                        action: 'New advisory button pushed',
                        documentTitle: 'Ford Advisory Management',
                        href: 'advisory.sre.ford.com',
                    })
                })
                .catch(() => {
                    history.push({
                        pathname: "/failureConfirmation"
                    });
                    trackEvent({
                        category: 'SubmitForm',
                        action: 'Create new advisory failed',
                        documentTitle: 'Ford Advisory Management',
                        href: 'advisory.sre.ford.com',
                    })
                });
        };
        const handleRegionChange = name => event => {
            formData.regions = {...formData.regions, [name]: event.target.checked};
            updateBulkMail();
        };

        const handlePriorityChange = event => {
            formData.priority = event.target.value;
            updateBulkMail();
        };

        function handlePriorityForInformationalAdvisory(event) {
            if (event.target.value === "Informational" && event.target.checked) {
                formData.priority = "Non-Incident";
            } else {
                formData.priority = "Medium";
            }

            updateBulkMail();
        }



        const handleInformationalAdvisoryChange = event => {
            setIsInformationalAdvisory(event.target.checked);
            if(!event.target.checked) {event.target.value = "Initial"}
            handleStatusChange(event);
            console.log(event.target.value + "\n");
            handlePriorityForInformationalAdvisory(event);
        };


        const handleStatusChange = event => {
            formData.status = event.target.value;
            if (event.target.value !== "Initial" && !formData.endDate) {
                formData.endDate = new Date();
            }
            if (
                event.target.value !== "Initial" &&
                !formData.businessImpactDetails.biEndDate
            ) {
                formData.businessImpactDetails.biEndDate = new Date();
            }

            setInvalidContactInfoEmail("");
            setInvalidAdditionalEmail("");
        };
        const [showEndDate, setShowEndDate] = useState(false);
        const [showBiEndDate, setShowBiEndDate] = useState(false);
        const [showRootCause, setShowRootCause] = useState(false);
        const [bulkMail, setBulkMail] = useState([]);
        const [statusIsNotInitialAtStart] = useState(formData.status !== "Initial")
        const [isInformationalAdvisory, setIsInformationalAdvisory] = useState(
            false
        );
        const [invalidContactInfoEmail, setInvalidContactInfoEmail] = useState("");
        const [invalidAdditionalEmail, setInvalidAdditionalEmail] = useState("");

        if (statusIsNotInitialAtStart) {
            formData.endDate = new Date();
            formData.businessImpactDetails.biEndDate = new Date();
        }

        function formatInvalidEmails() {
            if (invalidContactInfoEmail || invalidAdditionalEmail) {
                let commaSeparate =
                    invalidContactInfoEmail && invalidAdditionalEmail ? ", " : "";
                return invalidContactInfoEmail + commaSeparate + invalidAdditionalEmail;
            }
            return "";
        }

        function handleRemoveRootCause() {
            formData.rootCause = "";
            setShowRootCause(false);
        }

        function handleRemoveEnd() {
            setShowEndDate(false);
            formData.endDate = null;
        }

        function handleRemoveBiEnd() {
            setShowBiEndDate(false);
            formData.businessImpactDetails.biEndDate = null;
        }

        const useStyles = makeStyles(theme => ({
            selectors: {
                margin: theme.spacing(1),
                minWidth: 150
            }
        }));
        const updateBulkMail = () => {
            const fetchData = async () => {
                const regions = formData.getRegionsFromForm(formData.regions);

                const bulkMail = await bulkMailInfoAPIClient.getBulkMailInfo(
                    regions,
                    formData.priority
                );
                setBulkMail(bulkMail);
            };
            fetchData();
        };

        const stylingClasses = useStyles();

        return <Grid container spacing={3}>
            <CreateAdvisoryForm
                data={formData}
                handleStatusChange={handleStatusChange}
                handleRegionChange={handleRegionChange}
                showEndDate={showEndDate}
                setShowEndDate={setShowEndDate}
                showBiEndDate={showBiEndDate}
                setShowBiEndDate={setShowBiEndDate}
                showRootCause={showRootCause}
                setShowRootCause={setShowRootCause}
                handleRemoveEnd={handleRemoveEnd}
                handleRemoveBiEnd={handleRemoveBiEnd}
                handleRemoveRootCause={handleRemoveRootCause}
                handleSubmit={handleSubmit}
                stylingClasses={stylingClasses}
                bulkMail={bulkMail}
                handlePriorityChange={handlePriorityChange}
                isInformationalAdvisory={isInformationalAdvisory}
                handleInformationalAdvisoryChange={handleInformationalAdvisoryChange}
                setInvalidContactInfoEmail={setInvalidContactInfoEmail}
                setInvalidAdditionalEmail={setInvalidAdditionalEmail}
                invalidEmails={formatInvalidEmails()}
                submitDisabled={getDisabledForCreate}
                isFinalAdvisory={false}
            />
        </Grid>

    }
);
