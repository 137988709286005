import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import {DisplayErrorMessage} from "./DisplayErrorMessage";
import createConfig from "../config";

import {observer} from "mobx-react";

export const FamTextField = observer(props => {
  const [message, setMessage] = useState("");
  const config = createConfig(window.location.hostname);

  const handleInputChange = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    if (config.validationconfig.inputFields[name] && value.length > config.validationconfig.inputFields[name].maxLength) {
      setMessage("You have exceeded the maximum character limit of " + config.validationconfig.inputFields[name].maxLength + ". Please edit your message and try again.");
    } else {
      setMessage("");
    }   
  };

  return (
      <>
    <TextField
      multiline
      fullWidth
      variant="outlined"
      {...props}
      onChange={event => {
          handleInputChange(event)
          props.onChange(event)
      }
      }
      InputLabelProps={{ shrink: true }}
    />
    <DisplayErrorMessage content={message} />
</>
);
});
