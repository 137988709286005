import React from 'react';
import {slackChannelURL, webexSpaceURL} from "./Confirmation/CommunityChannelInfo";
import webexLogo from "../assets/Logos/webex.svg"
import slackLogo from "../assets/Logos/slackLogo.png"
import integrationLogo from "../assets/Logos/integrationLogo.svg"
import newsieLogo from "../assets/Logos/newsieLogo.svg"
import bulkmailLogo from "../assets/Logos/bulkmailLogo.svg"

function clickableFooterIcon(href: string, logoSrc, altText: string, iconLabel: JSX.Element) {
    return <a style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "52px",
        color: "#FFFFFF",
        textDecoration: "none"
    }} href={href}>
        <div style={{
            display: "flex",
            justifyContent: "center",
            width: "25px",
            height: "25px",
            borderRadius: "50%",
            backgroundColor: "#FCFCFC"
        }}><img style={{padding: "10%", height: "15px", width:"15px", alignSelf: "center"}} src={logoSrc} alt={altText}/></div>
        {iconLabel}</a>;
}

function littleBorderBetweenTheThings() {
    return <div style={{width: "2px", backgroundColor: "#666666", height: "25px"}}/>;
}

const Footer = () => {
    let newsieURL = "https://newsie.ford.com/platform-enablement/b193002f-5db5-4546-beaf-89b29126f775/Ford%20Advisory%20Management%20FAM%20/profile";
    let integrationUrl = "https://fam-ui-integration.apps.pd01i.edc1.cf.ford.com/";
    let bulkmailURL = "http://bulkmail.ford.com/~bulkmail/"
    return (
        <div title="footer" style={{
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
            marginTop: "2%",
            marginLeft: "-19px",
            width: "calc(100% + 43px)",
            paddingTop: "1%",
            paddingBottom: "1%",
            gap: "7px",
            backgroundColor: "#383838",
        }}>
            {clickableFooterIcon(integrationUrl, integrationLogo, "Pre-Prod Logo", <>Pre-Prod</>)}
            {littleBorderBetweenTheThings()}
            {clickableFooterIcon(webexSpaceURL, webexLogo, "Webex Logo", <>Webex</>)}
            {littleBorderBetweenTheThings()}
            {clickableFooterIcon(slackChannelURL, slackLogo, "Slack Logo", <>Slack</>)}
            {littleBorderBetweenTheThings()}
            {clickableFooterIcon(newsieURL, newsieLogo, "Newsie Logo", <>Newsie</>)}
            {littleBorderBetweenTheThings()}
            {clickableFooterIcon(bulkmailURL, bulkmailLogo, "Bulkmail Logo", <>Bulkmail</>)}
        </div>
    );
};

export default Footer;
