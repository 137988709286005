export default function constructConfig(hostName) {
  const ADFS_URL_EDC1_PROD =
      "https://fd-usr-sso.login.sys.pd01.edc1.cf.ford.com/oauth/authorize?client_id=67cf8778-45bc-4aee-bc3e-e2e35a6ba547&response_type=token+id_token&redirect_uri=";

  const ADFS_URL_EDC1_DEV =
      "https://fd-usr-sso.login.sys.pp01.edc1.cf.ford.com/oauth/authorize?client_id=04871532-b945-475c-85f5-1b1bf83b3265&response_type=token+id_token&redirect_uri=";
      
  const prodAdfsUrl = "https://corp.sts.ford.com/adfs/oauth2/authorize?client_id=urn:fam_adfs:clientid:web_famapplication:prod&resource=urn:fam_adfs:resource:web_famapplication:prod&response_type=token&redirect_uri=";

  const nonProdAdfsUrl =  "https://corpqa.sts.ford.com/adfs/oauth2/authorize?client_id=urn:fam_adfs:clientid:web_famapplication:dev&resource=urn:fam_adfs:resource:web_famapplication:dev&response_type=token&redirect_uri=";

  const validationconfig = {
    inputFields: {
      product: {
        label: "Product",
        maxLength: 254,
      },
      situationbackground: {
        label: "Situation Background",
        maxLength: 2000,
      },
      rootCause: {
        label: "Root Cause",
        maxLength: 2000,
      },
      usersImpacted: {
        label: "Users Impacted",
        maxLength: 2000,
      },
      impactDetails: {
        label: "Impact Details",
        maxLength: 2000,
      },
      updateSituationBackground: {
        label: "Update Situation/Background",
        maxLength: 2000,
      },
      updateBusinessImpact: {
        label: "Update Business Impact Details",
        maxLength: 2000,
      }
    },
  };


  if (hostName.includes("localhost")) {
    return {
      baseUrl: "http://localhost:3000",
      baseApiUrl: "http://localhost:8080/api",
      baseApiUrlWithoutApiPath: "http://localhost:8080",
      adfsUrl: nonProdAdfsUrl,
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    };
  }
  if (hostName.includes("advisory.sre.ford.com") || hostName.includes("advisory-sre.prod.cf.app.ford.com") || hostName.includes("advisory-sre.cf.app.ford.com") ) {
    return {
      baseUrl: "https://advisory.sre.ford.com",
      baseApiUrl: "https://fam-api-prod.ford.com/api",
      baseApiUrlWithoutApiPath: "https://fam-api-prod.ford.com",
      adfsUrl: prodAdfsUrl,
      matomoShouldBeDisabled: false,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    };
  }
  if (hostName.includes("pd01i") && hostName.includes("integration")) {
    return {
      // baseUrl: "https://fam-ui-integration.apps.pd01i.edc1.cf.ford.com",
      baseUrl: `https://${hostName}`,
      baseApiUrl: "https://SRE-FAM-Integration.apps.pd01i.edc1.cf.ford.com/api",
      baseApiUrlWithoutApiPath: "https://SRE-FAM-Integration.apps.pd01i.edc1.cf.ford.com",
      adfsUrl: prodAdfsUrl,
      matomoShouldBeDisabled: true,
      shouldValidate: true,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    }
  }
  if (hostName.includes("pp01i")) {
    return {
      baseUrl:
          "https://fam-ui-dev.apps.pp01i.edc1.cf.ford.com",
      baseApiUrl: "https://fam-dev.apps.pp01i.edc1.cf.ford.com/api",
      baseApiUrlWithoutApiPath: "https://fam-dev.apps.pp01i.edc1.cf.ford.com",
      adfsUrl: nonProdAdfsUrl,
      matomoShouldBeDisabled: true,
      shouldValidate: false,
      validationconfig: validationconfig,
      advisoryDaysToRetrieve: 29
    };
  }
}


