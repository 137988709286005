import axios from "axios";
import {getRequestConfig} from "../../ClientRequestHelper";
import createConfig from "../../config.js";

export default class ViewAllAdvisoriesAPIClient {

    constructor(serviceUrl) {
        this.serviceUrl = serviceUrl;
    }

    getAllAdvisories() {
        const config = createConfig(window.location.hostname);
        const url = `${this.serviceUrl}/advisory/listAllByDays/${config.advisoryDaysToRetrieve}`;
        return axios.get(url, getRequestConfig())
            .then(response => {
                return Promise.resolve(this.processResponse(response.data));
            }).catch((error) => {
                return Promise.reject(error)
            });
    }

    processResponse(response) {
        return response.map(advisoryFromServer => {
            advisoryFromServer.advisoryForm.situationBackground.submittedTime = new Date(
                advisoryFromServer.advisoryForm.situationBackground.submittedTime + "Z"
            );
            advisoryFromServer.advisoryForm.submittedTime = new Date(
                advisoryFromServer.advisoryForm.submittedTime + "Z"
            );
            return advisoryFromServer;
        }).sort(function (advisory1, advisory2) {
            return advisory2.advisoryForm.submittedTime - advisory1.advisoryForm.submittedTime;
        });
    }

    getPageOfAdvisories() {
        const url = `${this.serviceUrl}/advisory/page`;
        return axios.get(url, getRequestConfig())
            .then(response => {
                return Promise.resolve(this.processResponse(response.data.content));
            }).catch((error) => {
                return Promise.reject(error)
            });
    }
}